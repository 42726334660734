/* eslint-disable no-undef */
import './App.css';
import './counter.css';
import React, { useEffect, useMemo, useState } from 'react';
import twitter from './assets/twitter.png';
import discord from './assets/discord.png';
import logo from './assets/logo.png';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import Countdown from 'react-countdown';
import { bsc } from 'wagmi/chains'

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

const tweet = () => {
	window.open("#");
}

const discrd = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("https://sv.luxury/");
}

let ABI = [];

let address = "";
//let address = "0xE7268C3BC66b4dA84925D16110aE81391F26061d";

const maxSupply = 555;

const Home = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const publicClient = createPublicClient({
		chain: bsc,
		transport: http()
	})

	const [_totalSupply, settotalSupply] = useState(0)
	const [statusError, setstatusError] = useState("")
	const [statusLoading, setstatusLoading] = useState("")
	const [success, setsuccess] = useState("")
	const [statusErrorWL, setstatusErrorWL] = useState("")
	const [statusLoadingWL, setstatusLoadingWL] = useState("")
	const [successWL, setsuccessWL] = useState("")
	const [nftMintingAmount, setnftMintingAmount] = useState(1)
	const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
	const [_cost, set_publicSaleCost] = useState("")
	const [_wlcost, set_wlcost] = useState("")
	const [_max_per_wallet, set_max_per_wallet] = useState(10)
	const [_owner, set_owner] = useState("")
	const [myNFTWallet, setmyNFTWallet] = useState(0)
	const [_whitelist_mint_status, set_whitelist_mint_status] = useState("")
	const [_public_mint_status, set_public_mint_status] = useState("")
	const [_whitelistLimitPerWallet, set_whitelistLimitPerWallet] = useState("")
	const [_whitelistCount, set_whitelistCount] = useState("")
	const [_whitelistLimit, set_whitelistLimit] = useState("")
	const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
	const [_wlEligible, setWLEligible] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_freeMinLimit, set_OverallFreeMintLimit] = useState(600);
	const [_stateNow, set_stateNow] = useState(Date.now());
	const [_wlMinted, setWLMinted] = useState();

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}
	//........................................//
	const contract = {
		address: address,
		abi: ABI
	}

	async function handleConnect() {
		if (chain.id !== 56) {
			switchNetwork(56)
		}

		var data = await getBalance();
		setmyNFTWallet(Number(data.data));
		console.log("myNFTWallet :" + data.data);
		setConnected(true);
	}

	const { refetch: getTotalSupply } = useContractRead({
		...contract,
		functionName: 'totalSupply',
	})

	const { refetch: getCost } = useContractRead({
		...contract,
		functionName: 'publicSaleCost',
	})

	const { refetch: getBalance } = useContractRead({
		...contract,
		functionName: 'balanceOf',
		args: [walletAddress ? walletAddress : '0x']
	})


	useEffect(() => {

		async function fetchData() {
			var data = await getTotalSupply();

			settotalSupply(Number(data.data))
			console.log("totalSupplyFromUseffect : " + data.data)

		}
		async function fetchData2() {

			var data1 = await getBalance();
			setmyNFTWallet(Number(data1.data));
			console.log("myNFTWallet :" + data1.data);
		}

		async function fetchData3() {

			var data2 = await getCost();
			set_publicSaleCost(Number(data2.data) / 10 ** 18);
			console.log("publicSaleCost :" + data2.data);
		}

		if (_connected) {
			fetchData();
			fetchData2();
			fetchData3();
		}

		// eslint-disable-next-line no-use-before-define
	}, [_connected, getBalance, getCost, getTotalSupply]);

	const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply' },
			{ ...contract, functionName: 'max_per_wallet' },
			{ ...contract, functionName: 'owner' },
			{ ...contract, functionName: 'publicSaleCost' },
			{ ...contract, functionName: 'PUBLIC_MAX_SUPPLY' },
			{ ...contract, functionName: 'public_mint_status' },
		]
	},)

	useMemo(() => {

		if (isSuccess === true) {
			settotalSupply(Number(data[0].result))
			set_max_per_wallet(Number(data[1].result))
			set_owner(data[2].result)
			set_publicSaleCost(Number(data[3].result) / 10 ** 18); // Convert WEI to ETH			
			set_MAX_SUPPLY(data[4].result)
			set_public_mint_status(data[5].result)
		}
	}, [_totalSupply, data, isSuccess])



	const { writeAsync } = useContractWrite({
		...contract,
		functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})
	//........................................//

	async function onPlus() {

		console.log("_public_mint_status :" + _public_mint_status);
		var nftIhave = nftMintingAmount + myNFTWallet;


		console.log("myNFTWallet : " + myNFTWallet);
		console.log("nftMintingAmount : " + nftMintingAmount);

		console.log("nftIhave : " + nftIhave);


		console.log("1");
		if (_public_mint_status) {
			console.log("2");

			if (nftIhave < _max_per_wallet) {
				//if (nftIhave < 1) {
				console.log("3");

				setnftMintingAmount(nftMintingAmount + 1);
				console.log("4");


				//}
			}
		}


	}

	async function onMinus() {
		if (nftMintingAmount != 1) {
			setnftMintingAmount(nftMintingAmount - 1)
		}
	}

	async function onMint() {
		try {
			var mintCost;
			var fullTotalSupply = 0;
			fullTotalSupply = (nftMintingAmount) + (_totalSupply);
			console.log("fullTotalSupply : " + fullTotalSupply);


			if (_owner === walletAddress) {

				mintCost = '0'

			} else {

				if (fullTotalSupply <= 0) {
					mintCost = '0'
				} else

					if (_public_mint_status) {
						//mintCost =  parseFloat(_cost)
						mintCost = _cost;
						console.log("mintCostB : " + mintCost);
					}
			}

			mintCost = (mintCost * nftMintingAmount).toString();
			console.log("nftMintingAmountA : " + nftMintingAmount)

			console.log("mintCostA : " + mintCost);


			setstatusLoading(true)
			setstatusError(false)

			var res = await writeAsync({
				args: [nftMintingAmount],
				value: parseEther(mintCost)// mintCost
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)

			}
		}
		catch (e) {
			console.log(e)
			setstatusError(true)
			setstatusLoading(false)

		}
	}

	return (
		<div class="allWrap">
			<div class="light">
					<div class="headers">

						<div class="headers2">

							<div class="logo" onClick={homeLink}><img class="logoPic" src={logo} /></div>

							<div class="right">

								<div class="icons">
									<div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
									<div class="socialIcon"><img onClick={discrd} src={discord} /></div>
								</div>

								<div class="connect2">
									<a class="btn" href="#">Connect Wallet</a>
								</div>

							</div>

						</div>

					</div>

					<div class="introduction">
						<div class="intro1">Welcome to</div>
						<div class="intro2">EL SALVADOR LUXURY ACCESS</div>
						<div class="intro2-mob">EL SALVADOR</div>
						<div class="intro2-mob">LUXURY ACCESS</div>
					</div>

					<div className='btnsMain'>

						<div className='eachBtns'>
							<div>New to sv.luxury?</div>
							<button className='btn1'>CREATE AN ACCOUNT</button>
						</div>

						<div className='eachBtns'>
							<div>Already have an account?</div>
							<button className='btn2'>LOGIN TO BACKOFFICE</button>
						</div>

					</div>


			</div >

			<div className='footer'>© 2024 SV.LUXURY. All Rights Reserved</div>

		</div >
	)

}
export default Home;
